import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e26107e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "svg-icon",
  "aria-hidden": "true"
}
const _hoisted_2 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("use", { "xlink:href": _ctx.iconName }, null, 8, _hoisted_2)
  ]))
}