<template>
  <!-- <div
    v-if="isExternal"
    :style="styleExternalIcon"
    class="svg-external-icon svg-icon"
    v-on="$listeners"
  /> -->
  <svg class="svg-icon" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const iconName = computed(() => {
      return `#icon-${props.iconClass}`;
    });
    const svgClass = function () {
      if (props.className) {
        return "svg-icon " + props.className;
      } else {
        return "svg-icon";
      }
    };

    return {
      iconName,
      svgClass,
    };
  },
});
</script>

<style scoped>
.svg-icon {
  /* width: 1em;
    height: 1em; */
  width: 12px;
  height: 12px;
  /* vertical-align: -0.15em; */
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}

.icon-toggle {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
}
.icon-toggle:hover {
  color: #0f58d6;
}

.el-button span {
  display: flex;
  align-items: center;
}
</style>
