import hyRequest from "../index";

import { IAccount, ILoginResult, OLoginState } from "./type";
import { IDataType } from "../types";

enum LoginAPI {
  AccountLogin = "/login",
  LoginUserInfo = "/users/", // 用法: /users/1
  UserMenus = "/role/", // 用法: role/1/menu
}

export function accountLoginRequest(account: IAccount) {
  return hyRequest.post<IDataType<ILoginResult>>({
    url: LoginAPI.AccountLogin,
    data: account,
  });
}

export function requestUserInfoById(id: number) {
  return hyRequest.get<IDataType>({
    url: LoginAPI.LoginUserInfo + id,
    showLoading: false,
  });
}

export function requestUserMenusByRoleId(id: number) {
  return hyRequest.get<IDataType>({
    url: LoginAPI.UserMenus + id + "/menu",
    showLoading: false,
  });
}
// 登录接口
export function requestUserLogin(params: IAccount) {
  return hyRequest.get<IDataType>({
    url: "/base/auth/login",
    params: params,
  });
}

//获取验证码
export function requestVerify() {
  return hyRequest.get<IDataType>({
    url: "/base/auth/verify/code",
  });
}

/**
 * 微软获取token
 */
export function gotoMicor() {
  return hyRequest.get<IDataType>({
    url: "/auth/oauth/login/microsoft_365",
  });
}

/**
 * 微软登录页面获取
 */
export function otherLogin(params: OLoginState) {
  return hyRequest.post<IDataType>({
    url: "/auth/oauth/callback/microsoft_365",
    params,
  });
}
