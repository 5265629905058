import { createApp } from "vue";
import { globalRegister } from "./global";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./assets/css/index.less";
import { setupLoadMoreDirective } from "./utils/loadmore";
import selectloadMore from "@/utils/selectLoadMoreDirective";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import SvgIcon from "@/components/svg-icon";
import "@/icons"; // icon

const app = createApp(App);
setupLoadMoreDirective(app);
app.directive("selectloadMore", selectloadMore);
// 注册element-plus/其他
app.use(globalRegister);

app.use(store);
// path: /user => user
app.use(router);
app.use(ElementPlus);
app.component("SvgIcon", SvgIcon);
app.mount("#app");

//放在const app = createApp(App)这行的下面
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
