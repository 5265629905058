// service统一出口
import HYRequest from "./request";
import { BASE_URL, TIME_OUT } from "./request/config";

import localCache from "@/utils/cache";

const hyRequest = new HYRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config: any) => {
      // 携带token的拦截
      const token = localCache.getCache("ITO-Mps-Token");

      if (token) {
        config.headers.Authorization = [
          "/base/auth/verify/code",
          "/base/auth/login",
        ].includes(config.url)
          ? ""
          : `Bearer ${token}`;
      }

      return config;
    },
    requestInterceptorCatch: (err) => {
      return err;
    },
    responseInterceptor: (res) => {
      return res;
    },
    responseInterceptorCatch: (err) => {
      return err;
    },
  },
});

export default hyRequest;
