// 1.方式一: 手动的切换不同的环境(不推荐)
// const BASE_URL = 'http://coderwhy.org/dev'
// const BASE_NAME = 'coderwhy'

// const BASE_URL = 'http://coderwhy.org/prod'
// const BASE_NAME = 'kobe'

// const BASE_URL = 'http://coderwhy.org/test'
// const BASE_NAME = 'james'

// 2.根据process.env.NODE_ENV区分
// 开发环境: development
// 生成环境: production
// 测试环境: test

let BASE_URL = "";
const TIME_OUT = 10000;

if (process.env.NODE_ENV === "development") {
  // BASE_URL = "http://codercba.com:5000";
  // BASE_URL = "http://192.168.0.70:13024";
  // BASE_URL = "https://ims.skf4u.com.cn/api"
  BASE_URL = "/api";
  // BASE_URL = "http://192.168.0.64:13024";
} else if (process.env.NODE_ENV === "production") {
  // BASE_URL = "https://ims.skf4u.com.cn/api";
  BASE_URL = "https://imsapi.krunning.cn";
} else {
  BASE_URL = "http://192.168.0.229:13024";
}

export { BASE_URL, TIME_OUT };
