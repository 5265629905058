import type { Directive, App } from "vue";

const debounce = function (func: any, delay: any) {
  let timer: any = null;
  return function (this: any) {
    if (timer) clearTimeout(timer);
    timer = null;
    let self = this;
    let args = arguments;
    timer = setTimeout(() => {
      func.apply(self, args);
    }, delay);
  };
};

const loadMore: Directive = {
  mounted(el: any, binding: any, vnode: any) {
    const { expand } = binding.modifiers;
    
    // 使用更丰富的功能，支持父组件的指令作用在指定的子组件上
    if (expand) {
      /**
       * target 目标DOM节点的类名
       * distance 减少触发加载的距离阈值，单位为px
       * func 触发的方法
       * delay 防抖时延，单位为ms
       * load-more-disabled 是否禁用无限加载
       */
      let { target, distance = 0, func, delay = 200 } = binding.value;
      if (typeof target !== "string") return;
      let targetEl = el.querySelector(target);
      if (!targetEl) {
        console.log("找不到容器");
        console.log(delay,distance);
        return;
      }
      binding.handler = function () {
        const { scrollTop, scrollHeight, clientHeight } = targetEl;

        let disabled = el.getAttribute("load-more-disabled");
        disabled = vnode[disabled] || disabled;
        
        let height = scrollHeight - scrollTop;
          //当缩放时 scrollTop值 可能存在小数
        if (Math.floor(height) == clientHeight || Math.ceil(height) == clientHeight) {
          if (disabled == "true") return;
          func && func();
         
        }
        // if (scrollHeight <= scrollTop + clientHeight + distance) {
        //   console.log(156);
          
        //   if (disabled == "true") return;
        //   func && func();
        // }
      };
      targetEl.addEventListener("scroll", binding.handler);
    } else {
      binding.handler = debounce(function () {


        const { scrollTop, scrollHeight, clientHeight } = el;
        
        let height = scrollHeight - scrollTop;
          //当缩放时 scrollTop值 可能存在小数
        if (Math.floor(height) == clientHeight || Math.ceil(height) == clientHeight) {
          binding.value && binding.value();
        }
      }, 200);
      el.addEventListener("scroll", binding.handler);
    }
  },
  unmounted(el: any, binding: any) {
    let { arg } = binding;
    // 使用更丰富的功能，支持父组件的指令作用在指定的子组件上
    if (arg === "expand") {
      /**
       * target 目标DOM节点的类名
       * offset 触发加载的距离阈值，单位为px
       * method 触发的方法
       * delay 防抖时延，单位为ms
       */
      const { target } = binding.value;
      if (typeof target !== "string") return;
      let targetEl = el.querySelector(target);
      targetEl && targetEl.removeEventListener("scroll", binding.handler);
      targetEl = null;
    } else {
      el.removeEventListener("scroll", binding.handler);
      el = null;
    }
  },
};

export function setupLoadMoreDirective(app: App) {
  app.directive("loadMore", loadMore);
}

export default loadMore;
